import * as $ from 'jquery';
import Vue from 'vue';
import { Messages, Locale, Locales, Dictionary } from './i18n/types';
import { locale, storeLang } from './i18n/langcookie';
import { validated } from './i18n/validation';

import { hu } from './dict.hu';
import { en } from './dict.en';

export const dictionary: Dictionary = {
    hu,
    en
};

interface LoginFormState {
    locale: Locale;
    messages: Messages;
    username?: string;
    password?: string;
    loginError?: string;
    loginInProgress: boolean;
}

interface LoginFormMethods extends LoginFormState {
    loginDisabled: boolean;
}

export const LoginApp = new Vue({
    el: '#loginform',
    data: (): LoginFormState => ({
        locale: locale,
        messages: dictionary[locale],
        username: undefined,
        password: undefined,
        loginInProgress: false,
        loginError: undefined
    }),
    created(this: LoginFormState) {
        //const lang = guessLang();

        /*if (lang && lang !== this.locale) {
            redirectToLangPage(lang);
        } else {*/
            storeLang(this.locale);
        //}

        this.messages = dictionary[this.locale];
    },
    computed: {
        loginDisabled(this: LoginFormState): boolean {
            return this.loginInProgress || ! this.username || ! this.password;
        }
    },
    methods: /*<Vuei18nMethods>*/ {
        onSubmit(this: LoginFormMethods): void {
            if ( this.loginDisabled ) {
                return;
            }

            this.loginError = undefined;
            this.loginInProgress = true;

            console.log('POST');
            $.ajax({
                method: 'POST',
                url: '/ekonto/login/login_check_ajax',
                data: {
                    username: this.username,
                    password: this.password,
                    lang: this.locale
                },
                dataType: 'json'
            }).done(resp => {
                console.log(resp);

                if (resp.success) {
                    if (window.external && ('AutoCompleteSaveForm' in window.external)) {
                        // ocsmány IE hack, az IE nem akaródzik elmenteni a login mezőket
                        // JS-ből kezelt form esetén
                        console.log('IE AutoCompleteSaveForm');
                        (window.external as any).AutoCompleteSaveForm(document.getElementById("loginform"));
                    }

                    window.location.assign(resp.url);
                } else {
                    setTimeout(() => { this.loginInProgress = false; }, 2000);
                    this.loginError = resp.error;
                }
            }).fail((xhr, textStatus, errorThrown) => {
                setTimeout(() => { this.loginInProgress = false; }, 2000);
                console.log(xhr);
                console.log(textStatus);
                console.log(errorThrown);
                this.loginError = this.messages['error.network']({ error: errorThrown.toString() });
            });
        },
        setLocale(this: LoginFormState, locale: Locale): void {
            storeLang(locale);
        },
        hideError(this: LoginFormState): void {
            this.loginError = undefined;
        }
        /*T(this: LoginFormState, key: string, params?: any) {
            return this.messages[key](params);
        },
        E(this: LoginFormState, error: ValidationError) {
            return this.messages[error.key](error.params);
        }*/
    },
    /*watch: <any> {
        username: validated('username').required().minLength(4).alphanumeric().build()
    }*/
});
