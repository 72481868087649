export const Locales = {
    hu: 'hu',
    en: 'en'
}

export type Locale = keyof typeof Locales;

export interface MessageFunction {
    (params?: any): string;
}

export interface Messages {
    readonly [x: string]: MessageFunction;
}

export type Dictionary = {
    readonly [L in Locale]: Messages
};