import * as jQuery from 'jquery';
import 'bootstrap/js/dist/modal.js';
import 'slick-carousel/slick/slick.min.js';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './css/slick-carousel-mod.css';
import './3rdparty/jquery-modal-video';
import './css/modal-video.min.css';
import './css/index.css';
import { getParameterByName } from './pages/utils';
export { LoginApp } from './login-app';
export { MaintenanceDialog } from './maintenance';

const $: any = jQuery;

($(".newModalVideo") as any).modalVideo();

window.onpopstate = (event: PopStateEvent) => {
    if (event.state && event.state.contentId) {
        createView(event.state.contentId, false);
    }
};

function createView(contentId: string, pushHistory: boolean) {
    $('div.main-content').each(function (this: HTMLElement) {
        this.style.display = (this.id === contentId) ? 'block' : 'none';
    });

    if (pushHistory) {
        history.pushState({contentId: contentId, title: contentId}, contentId, '#' + contentId);
    }
}

$(function () {
    if (window.location.hash) {
        const str = window.location.hash.substring(1);
        createView(str, true);
    } else {
        history.pushState({contentId: 'tartalom', title: 'tartalom'}, 'tartalom', '');
    }
});

$(document).ready(() => {
    $('#login-btn').click((e: Event) => {
        if (e) {
            e.preventDefault();
         }
         
         $("#login").slideToggle("slow");
         $("#loginform input[name='username']").focus();
    });
});

$(document).ready(() => {
    $('#login-close').click(() => {
        $('html, body').animate({
            scrollTop: $("#header").offset().top
        }, 1000, function () {
            $("#login").hide();
        });
    });
});

let bejelentkezes = getParameterByName('bejelentkezes');

if(bejelentkezes === 'open'){
    $("#login").slideToggle("slow");
}

$(document).ready(function () {
    $('.partner').slick({
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
});

$(document).ready(function () {
    $('.quotes').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        prevArrow: null,
        nextArrow: null,
    });
});

$(document).ready(function () {
    $('.slider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
    });
});

$(document).ready(() => {
    $('#scroll-to-szolgaltatoi').click(() => {
        $('html, body').animate({
            scrollTop: parseInt($("#szolgaltatoi").offset().top)
        }, 650);
    });
});

// dijnethatas nyj
$(document).ready(() => {
    var d0 = new Date("2024-10-01T00:00:00.000+02:00");
    var d1 = new Date("2024-11-01T00:00:00.000+01:00");
    var now = new Date();
    if ( window.location.search.includes('debug') ) {
        console.log("referrer: "+document.referrer);
        console.log("location: "+window.location.origin);
        
        //console.log(new Date("1970-01-01T00:00:00.000+00:00"));
        //console.log(new Date("1970-01-01T00:00:00.000+00:00").valueOf());
        //console.log(new Date("1970-01-01T00:00:00.000+02:00"));
        //console.log(new Date("1970-01-01T00:00:00.000+02:00").valueOf());
        console.log(d0+" "+d0.valueOf());
        console.log(d1+" "+d1.valueOf());
        console.log(now+" "+now.valueOf());
    }
    if ( !document.referrer.startsWith(window.location.origin) && now.valueOf() >= d0.valueOf() && now.valueOf() < d1.valueOf() ) {
        $('#dijnethatas_modal').modal('show');
        if ( window.location.search.includes('debug') ) {
            console.log("show modal");
        }
    } else {
        if ( window.location.search.includes('debug') ) {
            console.log("no modal");
        }
    }
});