import * as Cookies from 'es-cookie';
import { Locales, Locale } from './types';
import { COOKIE_DOMAIN, COOKIE_PATH, COOKIE_TIMEOUT } from '../constants';

export const locale: Locale = (document.getElementsByTagName('html')[0].getAttribute('lang') || Locales.hu).toLowerCase() as Locale;

const langPttrn = /lang=(HU|EN)/i;
function parseLang(query: string): string | undefined {
    const match = langPttrn.exec(query);

    if (match) {
        return match[1];
    }
}

export function guessLang(): Locale | undefined {
    let lang = parseLang(window.location.hash) 
        || parseLang(window.location.search)
        || Cookies.get('lang');
    
    if ( ! lang && window.localStorage && window.localStorage.getItem) {
        try {
            lang = window.localStorage.getItem('lang') || lang;
        } catch (error) {
            console.log(error);
        }
    }

    if (lang && Locales.hasOwnProperty(lang.toLowerCase())) {
        return lang.toLowerCase();
    }
    
    return undefined;
}

export function storeLang(lang: Locale): void {
    Cookies.set('lang', lang, {
        // egy évig nyugodtan megőrizheti a böngésző
        // a felhasználó nem véletlenül állította be
        expires: COOKIE_TIMEOUT, 
        // külső domainekről betöltődő állományok esetén ne küldjük ki
        // máshonnan hozzánk érkező felhasználók esetén viszont szükség van rá
        sameSite: 'lax',
        // állítsuk be az aktuális domain a süti érvényességi határaként
        // így az aldomainekre is érvényes lesz
        // ha nem állítanánk be akkor nem, csak az aktuálisra
        // (lásd a kiválló HTTP süti specifikációban)
        domain: COOKIE_DOMAIN,
        path: COOKIE_PATH
    });

    if (window.localStorage && window.localStorage.setItem) {
        try {
            // cant throw security exception based on policy
            // let's ingore that
            window.localStorage.setItem('lang', lang);
        } catch (error) {
            console.log(error);
        }
    }
}

/*export function redirectToLangPage(lang: Locale): void {
    if (lang == Locales.hu) {
        window.location.replace(`${window.location.protocol}//${window.location.host}${}`);
    } else {
        const page = `${window.location.protocol}//${window.location.host}/${lang}${window.location.pathname}`;
        window.location.replace(page);
    }
}*/