import Vue from 'vue';
import * as Cookies from 'es-cookie';
import { isBefore } from 'date-fns';
import { COOKIE_PATH } from './constants';

interface MaintenanceState {
  shown: boolean;
}

const MAINTENANCE_COOKIE = 'hide_maintenance';
const END_DATE = new Date("2020-07-17T06:00:00.000+02:00");

const isCookieSet = !!Cookies.get(MAINTENANCE_COOKIE);

export const MaintenanceDialog = new Vue({
  el: '#maintenance',
  data: (): MaintenanceState => ({
    shown: !isCookieSet && isBefore(new Date(), END_DATE)
  }),
  methods: {
    close(this: MaintenanceState): void {
      this.shown = false;
      Cookies.set(MAINTENANCE_COOKIE, 'true', { expires: END_DATE, sameSite: 'lax', path: COOKIE_PATH });
    }
  },
});
